import { FC, useRef, useEffect, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = { children: ReactNode };
const Portal: FC<Props> = ({ children }) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document?.querySelector<HTMLElement>('#portal');
    setMounted(true);
  }, []);

  return mounted && ref.current ? createPortal(<>{children}</>, ref.current) : null;
};

export default Portal;

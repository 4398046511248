import React, { FC } from 'react';
import cx from 'classnames';

const fixedNumberLength = 3;
const ProductOrderNumber: FC<{ className?: string; order?: number }> = ({
  className = '',
  order
}) => {
  const zeros = fixedNumberLength - (order?.toString().length || 0);
  let prefix = '';
  for (let i = 0; i < zeros; i++) prefix += '0';
  return (
    <div
      className={cx(
        'ProductOrderNumber flex flex-shrink-0 items-center justify-center rounded-lg w-fit px-2 h-5 bg-emerald-700',
        className
      )}
    >
      {!!order || order === 0 ? (
        <span className="ProductOrderNumber__number text-white text-2xs font-bold tracking-wider">
          P{prefix}
          {order}
        </span>
      ) : (
        <span className="ProductOrderNumber__number text-white text-2xs font-bold tracking-wider">
          N/A
        </span>
      )}
    </div>
  );
};

export default ProductOrderNumber;

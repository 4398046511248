import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { SessionProvider } from 'next-auth/react';
import NotificationsProvider from 'contexts/NotificationsContext';
import UserProvider from 'contexts/UserContext';
import ManufacturingProvider from 'contexts/ManufacturingContext';

import { Nav } from 'components';
import '../styles/globals.css';
import Notifications from 'components/layouts/Notifications';
import Search from 'components/Search';
import { SWRConfig } from 'swr';
import LoadingScreen from 'components/LoadingScreen';
import SearchContextProvider from 'contexts/SearchContext';

NProgress.configure({});

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const AuthenticatedRoute = ({ Component, pageProps }: AppProps) => {
  const user = useSession();
  const router = useRouter();

  useEffect(() => {
    if (!router.pathname.includes('/login') && user.status === 'unauthenticated')
      router.push('/login');
  }, [user, router]);

  return (
    <>
      <Nav />
      <main className="bg-stone-100">
        <Component {...pageProps} />
      </main>
      <Notifications />
      <Search />
    </>
  );
};

const App = (props: AppProps) => {
  return (
    <SessionProvider>
      <SWRConfig
        value={{
          fetcher: (endpoint, init) => fetch(`/api/v1/${endpoint}`, init).then(res => res.json())
        }}
      >
        <UserProvider>
          <NotificationsProvider>
            <SearchContextProvider>
              <ManufacturingProvider>
                <AuthenticatedRoute {...props} />
                <LoadingScreen />
              </ManufacturingProvider>
            </SearchContextProvider>
          </NotificationsProvider>
        </UserProvider>
      </SWRConfig>
    </SessionProvider>
  );
};

export default App;

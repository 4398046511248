import React, { FC } from 'react';
import cx from 'classnames';
import { MdClose } from 'react-icons/md';

import { useNotifications, useNotificationsMutate } from 'contexts/NotificationsContext';
import { Button } from 'components/base';

const Notifications: FC = () => {
  const { notifications } = useNotifications();
  const mutate = useNotificationsMutate();

  return (
    <div className="Notifications fixed bottom-0 right-0 max-w-[36rem] w-full px-5 md:px-0 z-30">
      <div className="Notifications__inner flex flex-col mb-6 md:mr-6">
        {notifications.map(notification => (
          <div
            key={`Notifications__inner__item--${notification.id}`}
            className={cx(
              'Notification w-full mb-6 last-of-type:mb-0 relative pl-4 pr-9 py-4 animate-slide-up drop-shadow-md',
              {
                'bg-green-100 border-l-4 border-green-300 text-green-800 font-bold':
                  notification.type === 'success',
                'bg-rose-100 border-l-4 border-rose-300 text-rose-800 font-bold':
                  notification.type === 'error',
                'bg-amber-100 border-l-4 border-amber-300 text-amber-800 font-bold':
                  notification.type === 'warning',
                'bg-stone-100 border-l-4 border-stone-300 text-stone-800 font-bold':
                  notification.type === 'message'
              }
            )}
          >
            <Button
              className="absolute top-0 right-0 pr-5 pt-5"
              variant="no-style"
              onClick={() => mutate?.dismissNotification(notification.id)}
            >
              <MdClose className="w-5 h-5" />
            </Button>
            <span className="text-sm">{notification.message}</span>
            {!!notification.action && !!notification.callback && (
              <Button
                className="text-xs text-inherit pr-4"
                variant="link-underline"
                onClick={notification.callback}
              >
                {notification.action}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;

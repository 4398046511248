import React, { FC } from 'react';
import { SearchResult } from 'escapod';
import { Card, Button } from 'components/base';
import Link from 'next/link';
import makeUrl from 'utils/makeUrl';
import { RiEdit2Line } from 'react-icons/ri';

type Props = {
  result: SearchResult;
};
const DefaultResult: FC<Props> = ({ result }) => {
  return (
    <Card className="Search__result__item mb-4" key={`Search__result__item--${result._id}`}>
      <div className="flex justify-between items-start">
        <div className="flex flex-col">
          <Link href={makeUrl(result)}>
            <a className="flex font-bold text-xl">
              {!!result.firstName && `${result.firstName} ${result.lastName}`}
              {!!result.name && `${result.name}`}
            </a>
          </Link>
          <div className="capitalize text-sm mt-2">{result._type}</div>
        </div>
        <div className="flex flex-col w-[115px]">
          <Link href={makeUrl(result)}>
            <Button variant="secondary-small" className="flex items-center justify-center">
              <RiEdit2Line className="flex-shrink-0" />
              <span className="ml-1">Edit</span>
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default DefaultResult;

import axios from 'axios';
import * as Escapod from 'escapod';

const BASE_URL = '/api/v1';

const Pods = {
  sheets: {
    fetch: async (id: string) => {
      return (await axios.get(`${BASE_URL}/sheets/${id}`)).data;
    },
    preview: async (
      id: string,
      config: { steps: string; inspections: string; costCenters: string }
    ) => {
      return (await axios.post(`${BASE_URL}/sheets/${id}/preview`, config)).data;
    },
    productionSchedule: async (): Promise<Escapod.ProductionScheduleRow[]> => {
      return (await axios.post(`${BASE_URL}/sheets/production_schedule`)).data;
    }
  },
  workflows: {
    fetchByIds: async (ids: string[]): Promise<Escapod.Workflow[]> => {
      return (await axios.get(`${BASE_URL}/workflows?include=${ids.join(',')}`)).data;
    },
    import: async (body: {}): Promise<Escapod.Workflow> => {
      return (await axios.post(`${BASE_URL}/services/workflows/import`, body)).data;
    },
    update: async (id: string, changes: Partial<Escapod.Workflow>) => {
      return (await axios.post(`${BASE_URL}/workflows/${id}/update`, changes)).data;
    }
  },
  products: {
    updateProgress: async (
      id: string,
      data: {
        workflow: string;
        steps: string[];
        inspections: string[];
        value: string;
        activity: Escapod.Activity;
      }
    ): Promise<{ product: Escapod.Product }> => {
      return (await axios.post(`${BASE_URL}/services/products/${id}/progress/update`, data)).data;
    },
    update: async (id: string, changes: Partial<Escapod.Product>) => {
      return (await axios.post(`${BASE_URL}/products/${id}/update`, changes)).data;
    },
    create: async (changes: Partial<Escapod.Product>) => {
      return (await axios.post(`${BASE_URL}/products/create`, changes)).data;
    },
    fetchByVin: async (vin: string): Promise<Escapod.Product> => {
      return (await axios.get(`${BASE_URL}/products?vin=${vin}`)).data;
    },
    fetchByIds: async (ids: string[]): Promise<Escapod.Product[]> => {
      return (await axios.get(`${BASE_URL}/products?include=${ids.join(',')}&useCdn=true`)).data;
    },
    fetchActive: async (): Promise<Escapod.Product[]> => {
      return (await axios.get(`${BASE_URL}/products?status=active`)).data;
    },
    reorder: async (
      productOrders: [Escapod.Product['_id'], Escapod.Product['order']][]
    ): Promise<any> => {
      const res = await axios.post(`${BASE_URL}/services/products/reorder`, {
        productOrders
      });
      return res.data;
    },
    delete: async (id: string) => {
      return (await axios.post(`${BASE_URL}/products/${id}/delete`)).data;
    },
    import: async (body: {
      customer: Partial<Escapod.Customer>;
      order: Partial<Escapod.Order>;
      product: Partial<Escapod.Product>;
    }): Promise<{
      customer: Escapod.Customer;
      product: Escapod.Product;
      order: Escapod.Order;
    }> => {
      return (await axios.post(`${BASE_URL}/services/products/import`, body)).data;
    },
    addBulletin: async (id: string, bulletin: Escapod.ProductBulletin) => {
      return (
        await axios.post(`${BASE_URL}/services/products/${id}/bulletins/create`, { bulletin })
      ).data;
    },
    resolveBulletin: async (id: string, bulletin: string, user: string) => {
      return (
        await axios.post(`${BASE_URL}/services/products/${id}/bulletins/resolve`, {
          bulletin,
          user
        })
      ).data;
    },
    advance: async (products: { [id: string]: Escapod.ProductToAdvance }) => {
      return (await axios.post(`${BASE_URL}/services/products/advance`, { products })).data;
    }
  },
  productLines: {
    update: async (id: string, changes: Partial<Escapod.ProductLine>) => {
      return (await axios.post(`${BASE_URL}/product_lines/${id}/update`, changes)).data;
    }
  },
  users: {
    fetch: async (): Promise<Escapod.SanityUser[]> => {
      return (await axios.get(`${BASE_URL}/users`)).data;
    },
    fetchByIds: async (ids: string[]): Promise<Escapod.SanityUser[]> => {
      return (await axios.get(`${BASE_URL}/users?include=${ids.join(',')}`)).data;
    },
    create: async (changes: Partial<Escapod.User>) => {
      return (await axios.post(`${BASE_URL}/users/create`, changes)).data;
    },
    update: async (id: string, changes: Partial<Escapod.User>) => {
      return (await axios.post(`${BASE_URL}/users/${id}/update`, changes)).data;
    }
  },
  customers: {
    update: async (id: string, changes: Partial<Escapod.Customer>) => {
      return (await axios.post(`${BASE_URL}/customers/${id}/update`, changes)).data;
    },
    create: async (changes: Partial<Escapod.Customer>) => {
      return (await axios.post(`${BASE_URL}/customers/create`, changes)).data;
    },
    delete: async (id: string) => {
      return (await axios.post(`${BASE_URL}/customers/${id}/delete`)).data;
    }
  },
  facilities: {
    update: async (id: string, changes: Partial<Escapod.Facility>) => {
      return (await axios.post(`${BASE_URL}/facilities/${id}/update`, changes)).data;
    },
    create: async (changes: Partial<Escapod.Facility>) => {
      return (await axios.post(`${BASE_URL}/facilities/create`, changes)).data;
    },
    delete: async (id: string) => {
      return (await axios.post(`${BASE_URL}/facilities/${id}/delete`)).data;
    }
  },
  zones: {
    update: async (id: string, changes: Partial<Escapod.Zone>) => {
      return (await axios.post(`${BASE_URL}/facilities/zones/${id}/update`, changes)).data;
    },
    create: async (changes: Partial<Escapod.Zone>) => {
      return (await axios.post(`${BASE_URL}/facilities/zones/create`, changes)).data;
    },
    delete: async (id: string) => {
      return (await axios.post(`${BASE_URL}/facilities/zones/${id}/delete`)).data;
    }
  },
  orders: {
    update: async (id: string, changes: Partial<Escapod.Order>) => {
      return (await axios.post(`${BASE_URL}/orders/${id}/update`, changes)).data;
    },
    create: async (order: Partial<Escapod.Order>) => {
      return (await axios.post(`${BASE_URL}/orders/create`, order)).data;
    },
    delete: async (id: string) => {
      return (await axios.post(`${BASE_URL}/orders/${id}/delete`)).data;
    }
  },
  tags: {
    fetch: async (): Promise<Escapod.Tag[]> => {
      return (await axios.get(`${BASE_URL}/tags`)).data;
    },
    fetchUnresolved: async (): Promise<Escapod.Tag[]> => {
      return (await axios.get(`${BASE_URL}/tags?fetchUnresolved=true`)).data;
    },
    fetchByProductIds: async (ids: string[]): Promise<Escapod.Tag[]> => {
      return (await axios.get(`${BASE_URL}/tags?products=${ids.join(',')}`)).data;
    },
    create: async (tag: Escapod.SanityDocumentUpload<Escapod.Tag>): Promise<Escapod.Tag> => {
      return (await axios.post(`${BASE_URL}/tags/create`, { tag })).data;
    },
    update: async (id: string, changes: Partial<Escapod.Tag>) => {
      return (await axios.post(`${BASE_URL}/tags/${id}/update`, changes)).data;
    },
    delete: async (id: string) => {
      return (await axios.post(`${BASE_URL}/tags/${id}/delete`)).data;
    },
    completeRework: async (id: string, group: string, rework: Escapod.ReworkItem) => {
      return (
        await axios.post(`${BASE_URL}/services/tags/${id}/rework/complete`, { group, rework })
      ).data;
    },
    updateRework: async (id: string, rework: Escapod.ReworkGroup[]) => {
      return (await axios.post(`${BASE_URL}/services/tags/${id}/rework/update`, { rework })).data;
    }
  },
  services: {
    references: async (id: string) => {
      return await axios.post(`${BASE_URL}/services/references/${id}`);
    },
    logEwiTime: async (data: Escapod.TimeTracking.EwiLog) => {
      return await axios.post(`${BASE_URL}/services/time_tracking/ewi/create`, data);
    },
    logQcEwiTime: async (data: Escapod.TimeTracking.QcEwiLog) => {
      return await axios.post(`${BASE_URL}/services/time_tracking/qcewi/create`, data);
    }
  }
};

export default Pods;

import React, { useState, useContext, createContext, useMemo, FC, ReactNode } from 'react';

type State = { searchString: string; searchIsActive: boolean };
const INITIAL_STATE: State = { searchString: '', searchIsActive: false };

type Mutate = {
  setSearchString: (searchString: string) => void;
  setSearchIsActive: (searchIsActive: boolean) => void;
  reset: () => void;
};

const SearchContext = createContext(INITIAL_STATE);
const SearchMutate = createContext<Mutate | null>(null);

const SearchContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [context, setContext] = useState(INITIAL_STATE);

  const mutate: Mutate = useMemo(
    () => ({
      setSearchString: searchString => setContext(context => ({ ...context, searchString })),
      setSearchIsActive: searchIsActive => setContext(context => ({ ...context, searchIsActive })),
      reset: () => setContext(INITIAL_STATE)
    }),
    [context]
  );

  return (
    <SearchContext.Provider value={context}>
      <SearchMutate.Provider value={mutate}>{children}</SearchMutate.Provider>
    </SearchContext.Provider>
  );
};

export const useSearchState = () => {
  const state = useContext(SearchContext);

  if (state === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return state;
};

export const useSearchMutate = () => {
  const mutate = useContext(SearchMutate);

  if (mutate === undefined) {
    throw new Error('useSearchMutate must be used within a SearchProvider');
  }

  return mutate;
};

export default SearchContextProvider;

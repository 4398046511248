import React, { useState, useContext, createContext, FC, ReactNode, useEffect } from 'react';
import { User, SanityUser } from 'escapod';
import { useSession } from 'next-auth/react';
import useSWR from 'swr';

type CurrentUser = User | null;
const INITIAL_STATE: CurrentUser = null;

export const UserState = createContext<CurrentUser>(INITIAL_STATE);
export const UserContext: FC<{ children?: ReactNode }> = ({ children }) => {
  const [context, setContext] = useState<CurrentUser>(INITIAL_STATE);
  const { data: session } = useSession();
  const { data: user } = useSWR<SanityUser>(
    session?.user.email
      ? [
          'users/get_by_email',
          {
            method: 'post',
            body: JSON.stringify({ email: session.user.email || '' })
          }
        ]
      : null
  );

  const combinedUser: CurrentUser = !!user && !!session ? { ...user, ...session } : null;

  useEffect(() => setContext(combinedUser), [user, session]);

  return <UserState.Provider value={context}>{children}</UserState.Provider>;
};

export const useCurrentUser = () => {
  const state = useContext(UserState);
  const defined = state !== undefined;
  if (!defined) throw new Error('useCurrentUser must be used within a UserProvider');
  return state;
};

export default UserContext;

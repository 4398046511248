import React, { FC } from 'react';
import { OriginalTopoVariants } from '../../constants/AssemblyTypeVariants';
import { Order } from '../../../pods/escapod';

const OgBadges: FC<{ order: Order }> = ({ order }) => {
  const trailerLineItem = order?.items?.find(
    item => item._type === 'lineItem' && item.name.includes('Original TOPO')
  );
  const variantStrings = trailerLineItem?._type === 'lineItem' && trailerLineItem.variants;
  const variants = !!variantStrings
    ? variantStrings.reduce((variants: { [key: string]: string }, variant) => {
        const [name, value] = variant.split(':');
        variants[name] = value;

        return variants;
      }, {})
    : {};

  const axle = order?.items?.some(
    item => item._type === 'lineItem' && item.name.toLowerCase().includes('freeride')
  )
    ? 'Upgraded'
    : 'Torsion';

  const brakes = order?.items?.some(
    item => item._type === 'lineItem' && item.name.toLowerCase().includes('brakes')
  )
    ? 'Electric Brakes'
    : 'No Brakes';

  const hex = OriginalTopoVariants.color[variants?.color as string]?.hex1;
  const name = OriginalTopoVariants.color[variants?.color as string]?.name;

  return (
    <div className="OgBadges flex text-[.5rem] flex-row justify-start md:justify-end mt-2 md:mt-0 drop-shadow-sm">
      <div className="flex p-[1px] rounded-sm bg-stone-600 mr-4 w-fit drop-shadow-sm">
        <strong className="uppercase px-2 py-1 text-white">Frame</strong>
        <div className="bg-white rounded-sm uppercase px-2 py-1">
          <strong>
            {axle} / {brakes}
          </strong>
        </div>
      </div>
      <div style={{ background: hex }} className="flex p-[1px] rounded-sm w-fit drop-shadow-sm">
        <strong className="uppercase px-2 py-1 text-white">Color</strong>
        <div className="bg-white rounded-sm uppercase px-2 py-1">
          <strong>{name}</strong>
        </div>
      </div>
    </div>
  );
};

export default OgBadges;

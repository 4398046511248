import React, { FC, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import { LoadingSpinner, TextField } from './base';
import { useSearchMutate, useSearchState } from 'contexts/SearchContext';
import { useDebounce } from 'usehooks-ts';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { SearchResult } from 'escapod';
import ProductResult from './layouts/Search/ProductResult';
import DefaultResult from './layouts/Search/DefaultResult';

const Search: FC = () => {
  const router = useRouter();
  const currentPage = useRef<string | null>(null);
  const { searchString, searchIsActive } = useSearchState();
  const mutate = useSearchMutate();
  const debouncedSearch = useDebounce<string>(searchString, 500);
  const { data: results, isValidating } = useSWR<SearchResult[]>(
    !!debouncedSearch ? `services/search?search=${debouncedSearch}` : null,
    { revalidateOnFocus: false }
  );
  const _onSearchChange = useCallback(
    (value: string) => {
      mutate?.setSearchString(value);
    },
    [mutate]
  );

  useEffect(() => {
    if (currentPage.current !== router.asPath) mutate?.reset();
    currentPage.current = router.asPath;
  }, [router.asPath, currentPage, mutate]);

  return (
    <div
      className={cx(
        'Search fixed left-0 right-0 top-12 bottom-0 flex justify-center items-start z-20 bg-stone-100 transition-all h-[100vh-40px] overflow-auto',
        {
          'opacity-0 pointer-events-none': !searchIsActive
        }
      )}
    >
      <div className="Search__container container">
        <div className="Search__header border-b-1 bg-stone-100 top-0 z-20 border-stone-200 pb-4 mb-4 pt-12 relative">
          <div className="flex items-center pb-4">
            <div className="Search__title font-bold text-stone-700 text-xl">Search</div>
            {isValidating && <LoadingSpinner className="ml-2 transition-opacity" />}
          </div>
          <TextField
            value={searchString}
            className="Search__TextField"
            ariaLabel="search"
            name="search"
            onChange={value => _onSearchChange(value as string)}
            elemRef={element => {
              if (searchIsActive && element) {
                element.focus();
              }
            }}
            onBlur={value =>
              !value || results?.length === 0 ? mutate?.setSearchIsActive(false) : null
            }
          />
        </div>
        <div
          className={cx('Search__results transition-opacity', {
            'opacity-50 pointer-event-none': isValidating
          })}
        >
          {!!results &&
            results.map((result: SearchResult) => {
              switch (result._type) {
                case 'product':
                  return <ProductResult product={result} />;
                default:
                  return <DefaultResult result={result} />;
              }
            })}
          {results?.length === 0 && !isValidating && !!searchString && (
            <div>No Results. Try another search.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;

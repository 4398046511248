import React, { FC, ReactNode } from 'react';
import cx from 'classnames';

const Tag: FC<{
  className?: string;
  variant?: 'normal' | 'success';
  children: ReactNode;
}> = ({ children, variant = 'normal', className = '' }) => (
  <div
    className={cx('Tag font-bold font-sans text-xs px-2 py-1 rounded-sm w-fit', className, {
      'bg-stone-200 text-stone-600': variant === 'normal',
      'bg-cyan-100 text-cyan-600': variant === 'success'
    })}
  >
    {children}
  </div>
);

export default Tag;

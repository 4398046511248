import React, { FC, useState, useContext, createContext, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import useSWR, { KeyedMutator } from 'swr';
import { useSession } from 'next-auth/react';
import { v4 as uuid } from 'uuid';
import { subMinutes } from 'date-fns';

import Pods from 'clients/Pods';
import ewisByInspectionId from 'utils/ewisByInspectionId';
import totalEstimatedTime from 'utils/totalEstimatedTime';
import {
  generateFromWorkflow,
  progressByBillOfProcess,
  progressByCostCenter
} from 'utils/progress';

import { useCurrentUser } from 'contexts/UserContext';
import { useNotificationsMutate } from 'contexts/NotificationsContext';

import {
  Workflow,
  Step,
  Inspection,
  Product,
  Tag,
  Order,
  Customer,
  Progress,
  WorkflowProgress,
  Activity,
  SanityDocumentUpload,
  TimeTracking,
  ReworkGroup,
  ReworkGroupStub,
  ReworkItemStub,
  ProgressObj
} from 'escapod';
import stepsByKey from 'utils/stepsByKey';
import inspectionsByKey from 'utils/inspectionsByKey';

type Mutate = {
  // TO-DO: Split these out into a ui-specific sub-type
  changeNote: (note: string) => void;
  changeMinutes: (minutes: number) => void;
  changePeople: (people: number) => void;
  changeFailureReason: (failureReason: Tag['type']) => void;
  stageStep: (step: string) => void;
  stageInspection: (inspection: string, remove: string) => void;
  failInspection: (inspection: string) => void;
  clearStagedChanges: () => void;
  filterCostCenters: (costCenter: string[]) => void;
  addReworkGroup: () => void;
  removeReworkGroup: (group: string) => void;
  addReworkItem: (group: string) => void;
  editReworkItem: (group: string, item: ReworkItemStub) => void;
  removeReworkItem: (group: string, item: ReworkItemStub) => void;
  toggleAdditionalStep: (step: string) => void;
  toggleShowTags: () => void;
  toggleShowMaterialNeeds: () => void;
  toggleCostCenterUtilities: () => void;
  togglePartsUtilities: () => void;
  toggleShowBulletins: () => void;
  // TO-DO: Split these out into a async sub-type
  initialize: (config: {
    product: Product;
    workflow: Workflow;
    customer: Customer;
    order: Order;
    swrMutator: KeyedMutator<Product>;
  }) => Promise<void>;
  commit: () => Promise<void>;

  // TO-DO: Deprecated
  setBillOfProcess: (billOfProcess: string) => void;
};

type State = {
  product: Product | null;
  activeProducts: Product[];
  workflow: Workflow | null;
  billOfProcess: string;
  order: Order | null;
  customer: Customer | null;
  progress: Progress | null;
  flattenedProgress: WorkflowProgress;
  progressByBillOfProcess: { [key: string]: ProgressObj };
  progressByCostCenter: {
    [key: string]: Pick<ProgressObj, 'totalTime' | 'completedTime'>;
  };
  ewisByInspectionId: { [key: string]: string };
  stepKeysByEwi: { [key: string]: string };
  stepsByKey: { [key: string]: Step };
  inspectionsByKey: { [key: string]: Inspection };
  tags: Tag[];
  showTags: boolean;
  showMaterialNeeds: boolean;
  showBulletins: boolean;
  costCenterUtilitiesActive: boolean;
  partUtilitiesActive: boolean;
  stagedChanges: {
    note: string;
    failureReason: Tag['type'] | null;
    additionalSteps: string[];
    steps: string[];
    inspections: string[];
    timeTrackingMinutes: number;
    timeTrackingPeople: number;
    rework: ReworkGroupStub[];
  };
  filters: {
    costCenters: string[];
  };
  swrMutator: KeyedMutator<Product> | null;
};

const INITIAL_STATE: State = {
  product: null,
  activeProducts: [],
  workflow: null,
  billOfProcess: '',
  order: null,
  customer: null,
  progress: null,
  flattenedProgress: { steps: {}, inspections: {} },
  ewisByInspectionId: {},
  stepsByKey: {},
  stepKeysByEwi: {},
  progressByBillOfProcess: {},
  progressByCostCenter: {},
  inspectionsByKey: {},
  tags: [],
  showTags: false,
  showMaterialNeeds: false,
  showBulletins: false,
  costCenterUtilitiesActive: false,
  partUtilitiesActive: false,
  stagedChanges: {
    note: '',
    failureReason: null,
    additionalSteps: [],
    steps: [],
    inspections: [],
    timeTrackingMinutes: 0,
    timeTrackingPeople: 1,
    rework: []
  },
  filters: {
    costCenters: []
  },
  swrMutator: null
};

const ManufacturingState = createContext<State>(INITIAL_STATE);
const ManufacturingMutate = createContext<Mutate | null>(null);

const ManufacturingContext: FC<{ children?: ReactNode }> = ({ children }) => {
  const [context, setContext] = useState<State>(INITIAL_STATE);
  const { data: tags, mutate: tagsMutate } = useSWR<Tag[]>(
    !!context.product?._id ? `tags?product=${context.product._id}` : null
  );
  const user = useCurrentUser();
  const notifications = useNotificationsMutate();

  const mutate: Mutate = {
    changeNote: note => {
      setContext({
        ...context,
        stagedChanges: { ...context.stagedChanges, note }
      });
    },
    changeMinutes: minutes => {
      setContext({
        ...context,
        stagedChanges: { ...context.stagedChanges, timeTrackingMinutes: minutes }
      });
    },
    changePeople: people => {
      setContext({
        ...context,
        stagedChanges: { ...context.stagedChanges, timeTrackingPeople: people }
      });
    },
    changeFailureReason: failureReason => {
      setContext({
        ...context,
        stagedChanges: { ...context.stagedChanges, failureReason }
      });
    },
    stageStep: step => {
      setContext({
        ...context,
        stagedChanges: {
          ...context.stagedChanges,
          steps: context.stagedChanges.steps.includes(step)
            ? context.stagedChanges.steps.filter(_step => _step !== step)
            : [...context.stagedChanges.steps, step]
        }
      });
    },
    stageInspection: (inspection, removeKey) => {
      setContext({
        ...context,
        stagedChanges: {
          ...context.stagedChanges,
          inspections: context.stagedChanges.inspections.includes(inspection)
            ? context.stagedChanges.inspections
                .filter(_inspection => _inspection !== inspection)
                .filter(_inspection => _inspection !== removeKey)
            : [...context.stagedChanges.inspections, inspection].filter(
                _inspection => _inspection !== removeKey
              )
        }
      });
    },
    failInspection: inspection => {
      setContext({
        ...context,
        stagedChanges: {
          ...INITIAL_STATE.stagedChanges,
          inspections: [inspection]
        }
      });
    },
    toggleAdditionalStep: additionalStep => {
      const stagedChanges = !!context.stagedChanges.additionalSteps.includes(additionalStep)
        ? {
            ...context.stagedChanges,
            additionalSteps: context.stagedChanges.additionalSteps.filter(
              step => step !== additionalStep
            )
          }
        : {
            ...context.stagedChanges,
            additionalSteps: [...context.stagedChanges.additionalSteps, additionalStep]
          };

      setContext({ ...context, stagedChanges });
    },
    toggleShowTags: () => {
      setContext({ ...context, showTags: !context.showTags });
    },
    toggleShowMaterialNeeds: () => {
      setContext({ ...context, showMaterialNeeds: !context.showMaterialNeeds });
    },
    toggleShowBulletins: () => {
      setContext({ ...context, showBulletins: !context.showBulletins });
    },
    toggleCostCenterUtilities: () => {
      setContext({
        ...context,
        costCenterUtilitiesActive: !context.costCenterUtilitiesActive
      });
    },
    togglePartsUtilities: () => {
      setContext({
        ...context,
        partUtilitiesActive: !context.partUtilitiesActive
      });
    },
    addReworkGroup: () => {
      setContext({
        ...context,
        stagedChanges: {
          ...context.stagedChanges,
          rework: [...context.stagedChanges.rework, { _key: uuid() }]
        }
      });
    },
    removeReworkGroup: group => {
      setContext({
        ...context,
        stagedChanges: {
          ...context.stagedChanges,
          rework: context.stagedChanges.rework.filter(reworkGroup => group !== reworkGroup._key)
        }
      });
    },
    addReworkItem: group => {
      setContext({
        ...context,
        stagedChanges: {
          ...context.stagedChanges,
          rework: [
            ...context.stagedChanges.rework.map(reworkGroup => {
              if (group !== reworkGroup._key) return reworkGroup;

              return {
                ...reworkGroup,
                items: [
                  ...(reworkGroup.items || []),
                  { _key: uuid(), _type: 'item' }
                ] as ReworkGroupStub['items']
              };
            })
          ]
        }
      });
    },
    editReworkItem: (group, item) => {
      setContext({
        ...context,
        stagedChanges: {
          ...context.stagedChanges,
          rework: context.stagedChanges.rework.map(reworkGroup => {
            if (group !== reworkGroup._key) return reworkGroup;

            return {
              ...reworkGroup,
              items: reworkGroup?.items?.map(reworkItem =>
                item._key === reworkItem._key ? item : reworkItem
              ) as ReworkGroupStub['items']
            };
          })
        }
      });
    },
    removeReworkItem: (group, item) => {
      setContext({
        ...context,
        stagedChanges: {
          ...context.stagedChanges,
          rework: [
            ...context.stagedChanges.rework.map(reworkGroup => {
              if (group !== reworkGroup._key) return reworkGroup;

              return {
                ...reworkGroup,
                items: reworkGroup?.items?.filter(reworkItem => item._key !== reworkItem._key) || []
              };
            })
          ]
        }
      });
    },

    filterCostCenters: costCenters => {
      setContext({ ...context, filters: { ...context.filters, costCenters } });
    },
    clearStagedChanges: () => {
      setContext({ ...context, stagedChanges: INITIAL_STATE.stagedChanges });
    },
    setBillOfProcess: billOfProcess => setContext({ ...context, billOfProcess }),
    initialize: async config => {
      try {
        const parsedProgress = JSON.parse(config.product.progress || '{}');
        const progress =
          !!Object.keys(parsedProgress).length &&
          Object.keys(parsedProgress).includes(config.workflow._id)
            ? parsedProgress
            : {
                ...parsedProgress,
                [config.workflow._id]: generateFromWorkflow(config.workflow, config.order)
              };
        const flattenedProgress = progress?.[config.workflow._id] || {};
        const _ewisByInspectionId = ewisByInspectionId(config.workflow);
        const _stepsByKey = stepsByKey(config.workflow);
        const stepKeysByEwi = Object.keys(_stepsByKey).reduce<{
          [key: string]: string;
        }>((stepKeysByEwi, key) => {
          const step = _stepsByKey[key];
          return { ...stepKeysByEwi, [step.ewi]: step._key };
        }, {});
        const _inspectionsByKey = inspectionsByKey(config.workflow);
        const _progressByBillOfProcess = progressByBillOfProcess(config.workflow, config.product);
        const _progressByCostCenter = progressByCostCenter(config.workflow, config.product);

        setContext({
          ...context,
          ...config,
          progress,
          flattenedProgress,
          ewisByInspectionId: _ewisByInspectionId,
          stepsByKey: _stepsByKey,
          stepKeysByEwi,
          inspectionsByKey: _inspectionsByKey,
          progressByBillOfProcess: _progressByBillOfProcess,
          progressByCostCenter: _progressByCostCenter,
          filters: {
            ...context.filters,
            costCenters: config.product.costCenters || []
          }
        });
      } catch (err) {
        Sentry.captureException(err);
        return notifications?.createNotification({
          type: 'error',
          message:
            'This operation could not be performed successfully. Try again in a few seconds or contact support for additional help.'
        });
      }
    },
    commit: async () => {
      const {
        workflow,
        progress,
        product,
        stagedChanges,
        stepKeysByEwi,
        ewisByInspectionId,
        stepsByKey
      } = context;

      if (!workflow || !progress || !product) {
        return console.error('Cannot change progress without workflow or product');
      }

      if (!user) {
        return console.error('Requires authenticated user');
      }

      try {
        const activityKey = uuid();
        const tags: SanityDocumentUpload<Tag>[] = stagedChanges.inspections
          .filter(inspection => inspection.includes('.FAIL'))
          .map(inspection => {
            const id = inspection.replace('.FAIL', '');
            const step = ewisByInspectionId[id];
            const stepKey = stepKeysByEwi[step];
            const operator = product.activity?.find(
              item => item._type === 'qualityControlAction' && item.steps.includes(stepKey)
            )?.user;

            return {
              _id: uuid(),
              _type: 'tag',
              workflow: { _type: 'reference', _ref: workflow._id },
              product: { _type: 'reference', _ref: product._id },
              type: stagedChanges.failureReason || 'materials',
              inspection: id,
              step,
              note: stagedChanges.note,
              inspector: { _ref: user._id, _type: 'reference' },
              operator: operator || { _ref: user._id, _type: 'reference' },
              additionalSteps: stagedChanges.additionalSteps,
              rework: stagedChanges.rework as ReworkGroup[],
              additionalStepsCompleted: [],
              resolvedAt: ''
            };
          });

        const activity: Activity = {
          _key: activityKey,
          _type: 'qualityControlAction',
          steps: stagedChanges.steps,
          inspections: stagedChanges.inspections,
          tags: tags.map(tag => ({
            _key: uuid(),
            _type: 'reference',
            _ref: tag._id
          })),
          note: stagedChanges.note,
          date: new Date().toISOString(),
          user: { _ref: user._id, _type: 'reference' }
        };

        const _ = !!tags.length ? await Promise.all(tags.map(tag => Pods.tags.create(tag))) : [];
        const response = await Pods.products.updateProgress(product._id, {
          workflow: workflow._id,
          steps: stagedChanges.steps,
          inspections: stagedChanges.inspections,
          value: activityKey,
          activity
        });
        const patch = response.product;

        if (!!stagedChanges.steps.length) {
          const step = stepsByKey[stagedChanges.steps[0]];
          const now = new Date();
          const data: TimeTracking.EwiLog = {
            finished: now.toISOString(),
            started: subMinutes(now, stagedChanges.timeTrackingMinutes).toISOString(),
            minutes: stagedChanges.timeTrackingMinutes,
            people: stagedChanges.timeTrackingPeople,
            ewi: step.ewi,
            workflow: workflow?.name || '',
            vin: product?.vin || '',
            operator: `${user.firstName} ${user.lastName}`,
            standardTime: step.estimatedTime || 1
          };

          await Pods.services.logEwiTime(data);
        }

        const newProgress = JSON.parse(patch.progress || '{}');
        const flattenedProgress = newProgress?.[workflow._id] || {};

        if (!!context.swrMutator) context.swrMutator(patch);

        tagsMutate();
        setContext({
          ...context,
          product: patch,
          progress: newProgress,
          flattenedProgress,
          stagedChanges: INITIAL_STATE.stagedChanges
        });
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        return notifications?.createNotification({
          type: 'error',
          message:
            'This operation could not be performed successfully. Try again in a few seconds or contact support for additional help.'
        });
      }
    }
  };

  return (
    <ManufacturingState.Provider value={{ ...context, tags: tags || [] }}>
      <ManufacturingMutate.Provider value={mutate}>{children}</ManufacturingMutate.Provider>
    </ManufacturingState.Provider>
  );
};

export const useManufacturingState = () => {
  const state = useContext(ManufacturingState);
  const defined = state !== undefined;

  if (!defined)
    throw new Error('useManufacturingState must be used within a ManufacturingProvider');

  return state;
};

export const useManufacturingMutate = () => {
  const mutate = useContext(ManufacturingMutate);
  const defined = mutate !== undefined;

  if (!defined)
    throw new Error('useManufacturingMutate must be used within a ManufacturingProvider');

  return mutate;
};

export default ManufacturingContext;

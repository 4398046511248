import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { FC, useMemo } from 'react';
import cx from 'classnames';

import { LoadingSpinner } from 'components/base';
import podsLogo from 'public/images/pods-logo-2.png';
import { useCurrentUser } from 'contexts/UserContext';

const LoadingScreen: FC = () => {
  const session = useSession();
  const user = useCurrentUser();
  const isLoading = useMemo(
    () => session.status === 'loading' || (session.status === 'authenticated' && !user),
    [session, user]
  );

  return (
    <div
      className={cx(
        'LoadingScreen fixed top-0 left-0 right-0 bottom-0 bg-stone-900 flex flex-col items-center justify-center transition-opacity',
        {
          'opacity-0 pointer-events-none': !isLoading
        }
      )}
    >
      <div className="Login__logo relative w-[188px] mb-12 animate-fade-in">
        <Image src={podsLogo} alt="PODS" />
      </div>
      <div className="flex items-center animate-fade-in">
        <span className="text-cyan-600 font-bold text-lg tracking-wide">Initializing</span>
        <LoadingSpinner className="text-cyan-600 ml-2" />
      </div>
    </div>
  );
};

export default LoadingScreen;

import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { CostCenter, SearchResult, Workflow } from 'escapod';
import Link from 'next/link';
import { Button, Card } from 'components/base';
import { RiCheckboxIndeterminateFill, RiEdit2Line, RiMapPinFill } from 'react-icons/ri';
import makeUrl from 'utils/makeUrl';
import useSWR from 'swr';

type Props = {
  product: SearchResult;
};
const ProductResult: FC<Props> = ({ product }) => {
  const { data: workflow } = useSWR<Workflow>(
    !!product.workflow ? `workflows/${product.workflow._ref}` : null
  );

  const costCenterList: CostCenter[] = useMemo(() => {
    if (!workflow || !product?.costCenters?.length) return [];

    return product.costCenters.reduce<CostCenter[]>((activeCostCenters, productCostCenterKey) => {
      const match = workflow.costCenters.find(
        costCenter => costCenter._key === productCostCenterKey
      );

      return !!match ? [...activeCostCenters, match] : activeCostCenters;
    }, []);
  }, [workflow, product]);

  return (
    <Card className="Search__result__item mb-4" key={`Search__result__item--${product._id}`}>
      <div className="flex justify-between items-start">
        <div className="flex flex-col">
          <Link href={makeUrl(product)}>
            <a className="flex items-center font-bold text-xl">
              {product.name}
              <div className="flex ml-2">
                <div
                  className={cx('rounded-circle h-2 w-2', {
                    'bg-stone-400': product?.status === 'queued',
                    'bg-yellow-400': product?.status === 'active',
                    'bg-emerald-400': product?.status === 'delivered',
                    'bg-rose-400': product?.status === 'abandoned'
                  })}
                />
              </div>
            </a>
          </Link>
          <div className="capitalize text-sm mt-2">{product._type}</div>
          <div className="flex">
            {costCenterList.map(costCenter => (
              <div
                key={`Search__cost-center-pill--${costCenter._key}`}
                className="w-fit rounded-full bg-purple-100 px-2 py-1 mt-2 mr-2"
              >
                <span className="flex items-center">
                  <RiMapPinFill className="w-[10px] h-[10px] mr-[6px] fill-purple-600" />
                  <strong className="text-xs tracking-wide text-purple-600 whitespace-nowrap">
                    {costCenter.name}
                  </strong>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-[115px]">
          <Link href={makeUrl(product)}>
            <Button variant="secondary-small" className="flex items-center justify-center">
              <RiEdit2Line className="flex-shrink-0" />
              <span className="ml-1">Edit</span>
            </Button>
          </Link>
          <Link href={`/manufacturing/${product._id}`}>
            <Button variant="neutral-small" className="flex items-center mt-2">
              <RiCheckboxIndeterminateFill className="mr-1 flex-shrink-0" />
              <span>Manufacturing</span>
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default ProductResult;

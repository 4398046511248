import React, { ForwardRefRenderFunction, ReactNode, MouseEvent, forwardRef } from 'react';
import cx from 'classnames';

export type Props = {
  variant:
    | 'primary'
    | 'secondary'
    | 'neutral'
    | 'error'
    | 'fail'
    | 'info'
    | 'primary-small'
    | 'secondary-small'
    | 'neutral-small'
    | 'error-small'
    | 'info-small'
    | 'link-underline'
    | 'link'
    | 'header-link'
    | 'header-link-active'
    | 'no-style';
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  children: ReactNode;
  disabled?: boolean;
};

export const Button: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { variant, children, className = '', onClick, type = 'button', disabled },
  ref
) => {
  const baseClasses = 'transition-all rounded-sm tracking-[.125px] whitespace-nowrap font-bold';
  let variantClasses = '';
  if (variant === 'primary') {
    variantClasses =
      'h-10 px-4 text-xs border-1 bg-emerald-600 border-emerald-600 hover:bg-emerald-700 hover:border-emerald-700 text-white';
  }
  if (variant === 'secondary') {
    variantClasses =
      'h-10 px-4 text-xs border-1 bg-cyan-200 border-cyan-300 text-cyan-900 hover:bg-cyan-300';
  }
  if (variant === 'neutral') {
    variantClasses =
      'h-10 px-4 text-xs border-1 bg-stone-200 border-stone-300 text-stone-800 hover:bg-stone-300';
  }
  if (variant === 'error') {
    variantClasses =
      'h-10 px-4 text-xs border-1 bg-rose-200 border-rose-300 hover:bg-rose-600 hover:text-white';
  }
  if (variant === 'fail') {
    variantClasses =
      'h-10 px-4 text-xs border-1 bg-rose-600 border-rose-700 hover:bg-rose-700 text-white';
  }
  if (variant === 'info') {
    variantClasses =
      'h-10 px-4 text-xs border-2 bg-transparent hover:bg-stone-200 border-stone-200 text-stone-500';
  }
  if (variant === 'primary-small') {
    variantClasses =
      'h-7 px-2 text-2xs border-1 bg-emerald-600 border-emerald-600 hover:bg-emerald-700 hover:border-emerald-700 text-white';
  }
  if (variant === 'secondary-small') {
    variantClasses =
      'h-7 px-2 text-2xs border-1 bg-cyan-200 border-cyan-300 text-cyan-900 hover:bg-cyan-300';
  }
  if (variant === 'neutral-small') {
    variantClasses =
      'h-7 px-2 text-2xs border-1 bg-stone-200 border-stone-300 text-stone-800 hover:bg-stone-300';
  }
  if (variant === 'error-small') {
    variantClasses =
      'h-7 px-2 text-2xs border-1 bg-rose-200 border-rose-300 hover:bg-rose-600 hover:text-white';
  }
  if (variant === 'info-small') {
    variantClasses =
      'h-6 px-2 text-2xs border-2 bg-transparent hover:bg-stone-200 border-stone-200 text-stone-500 tracking-wide';
  }
  if (variant === 'link-underline') {
    variantClasses = 'text-stone-500 bg-transparent underline hover:text-stone-900';
  }
  if (variant === 'link') {
    variantClasses = 'text-stone-500 bg-transparent hover:text-stone-900';
  }
  if (variant === 'header-link') {
    variantClasses =
      'text-stone-500 bg-transparent border-b-[2px] border-transparent hover:border-stone-500 pb-4 text-sm rounded-[0px]';
  }
  if (variant === 'header-link-active') {
    variantClasses =
      'text-cyan-500 border-0 border-b-[2px] border-cyan-500 bg-transparent hover:text-cyan-500 pb-4 text-sm rounded-[0px]';
  }

  return (
    <button
      ref={ref}
      className={cx(className, variantClasses, baseClasses, {
        'opacity-40 pointer-events-none': disabled
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default forwardRef<HTMLButtonElement, Props>(Button);

import React, { FC, ReactNode } from 'react';
import cx from 'classnames';

const Card: FC<{ children?: ReactNode; className?: string }> = ({ className, children }) => {
  return (
    <div
      className={cx(
        'Card bg-white border-1 border-stone-300 drop-shadow-sm rounded-sm p-4 md:p-5 relative',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;

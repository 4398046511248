import * as Sentry from '@sentry/nextjs';
import PackageJson from 'package.json';

Sentry.init({
  dsn: 'https://a33fc0ecc41940e78c1fa5f3fe83a731@o571547.ingest.sentry.io/4504595753730048',
  tracesSampleRate: 1.0,
  release: PackageJson.version,
  attachStacktrace: true,
  enabled: process.env.NODE_ENV === 'production'
});

import React, { FC } from 'react';
import cx from 'classnames';
import { VscLoading } from 'react-icons/vsc';

const LoadingSpinner: FC<{ className?: string }> = ({ className }) => (
  <div className={cx('LoadingSpinner transition flex-grow-0 h-4 w-4 animate-spin', className)}>
    <VscLoading className="h-4 w-4" />
  </div>
);

export default LoadingSpinner;

import * as Escapod from 'escapod';
const makeUrl = (document: Escapod.SearchResult) => {
  switch (document._type) {
    case 'zone':
      return `/facilities/zones/${document._id}`;
    case 'facility':
      return `/facilities/${document._id}`;
    case 'productLine':
      return `/product-lines/${document._id}`;
    default:
      return `/${document._type}s/${document._id}`;
  }
};
export default makeUrl;

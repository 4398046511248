import { Workflow, Step } from 'escapod';

export const stepsByKey = (workflow: Workflow) =>
  workflow.costCenters.reduce<{
    [key: string]: Step;
  }>((costCenters, costCenter) => {
    const billsOfProcess = costCenter.billsOfProcess.reduce<{
      [key: string]: Step;
    }>((billsOfProcess, billOfProcess) => {
      const steps = billOfProcess.steps.reduce<{ [key: string]: Step }>(
        (steps, step) => ({ ...steps, [step._key]: step }),
        {}
      );
      return { ...billsOfProcess, ...steps };
    }, {});
    return { ...costCenters, ...billsOfProcess };
  }, {});

export default stepsByKey;

import React, { RefObject, KeyboardEvent } from 'react';
import cx from 'classnames';
import { MdCheck, MdClose } from 'react-icons/md';

import LoadingSpinner from './LoadingSpinner';

type Props = {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  inputClassName?: string;
  ariaLabel: string;
  onBlur?: (e: string | number) => void;
  onFocus?: (e: string | number) => void;
  onChange?: (e: string | number) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  autoComplete?: boolean;
  maxLength?: number;
  type?: 'email' | 'text' | 'tel' | 'number' | 'password' | 'textarea' | 'date';
  value?: string | number;
  min?: number | string;
  max?: number | string;
  variant?: 'primary';
  validate?: () => undefined | string;
  disabled?: boolean;
  readOnly?: boolean;
  elemRef?: RefObject<HTMLInputElement | HTMLTextAreaElement> | ((ref: HTMLInputElement) => void); // eslint-disable-line no-unused-vars
  error?: string;
  showError?: boolean;
  loading?: boolean;
  success?: boolean;
  failure?: boolean;
  step?: string;
};

const TextField: React.FC<Props> = ({
  id,
  name,
  label,
  className = '',
  inputClassName = '',
  ariaLabel,
  pattern,
  placeholder,
  required = false,
  autoComplete = false,
  maxLength,
  type = 'text',
  value,
  min,
  max,
  variant = 'primary',
  disabled = false,
  readOnly = false,
  elemRef,
  onBlur = () => {
    return;
  },
  onFocus = () => {
    return;
  },
  onChange = () => {
    return;
  },
  onKeyDown = () => {
    return;
  },
  error,
  showError = false,
  loading = false,
  success = false,
  failure = false,
  step = 'text'
}) => {
  const _id = id || name;

  return (
    <div
      className={cx('TextField relative w-full', className, {
        [`TextField--style-${variant}`]: !!variant,
        'TextField--filled': !!value,
        'TextField--errored': error,
        'TextField--disabled': disabled
      })}
    >
      {label && (
        <label
          className="TextField__label block mb-1 text-sm font-bold text-stone-400"
          htmlFor={_id}
        >
          {label}
        </label>
      )}
      <div className="relative">
        {type === 'textarea' ? (
          <textarea
            className={cx(
              'TextField__textarea form-input w-full rounded-sm px-3 py-2 text-sm placeholder-stone-400',
              inputClassName,
              {
                'border-stone-300': !showError,
                'border-fire': showError,
                'bg-stone-100 text-stone-400': disabled
              }
            )}
            aria-label={ariaLabel}
            id={_id}
            name={name}
            onBlur={e => onBlur(e.target.value as string)}
            onFocus={e => onFocus(e.target.value as string)}
            onChange={e => onChange(e.target.value as string)}
            onKeyDown={e => onKeyDown(e)}
            placeholder={placeholder}
            required={required}
            maxLength={maxLength}
            value={value}
            autoComplete={autoComplete ? 'on' : 'off'}
            disabled={disabled}
            readOnly={readOnly}
            ref={elemRef as RefObject<HTMLTextAreaElement>}
            aria-invalid={!!error}
          />
        ) : (
          <input
            className={cx(
              'TextField__input form-input w-full rounded-sm px-3 py-2 text-sm placeholder-stone-400',
              inputClassName,
              {
                'border-stone-300': !showError,
                'border-fire': showError,
                'bg-stone-100 text-stone-400': disabled
              }
            )}
            aria-label={ariaLabel}
            id={_id}
            name={name}
            onBlur={e =>
              type === 'number'
                ? onBlur(parseFloat(e.target.value))
                : onBlur(e.target.value as string)
            }
            onFocus={e =>
              type === 'number'
                ? onFocus(parseFloat(e.target.value))
                : onFocus(e.target.value as string)
            }
            onChange={e =>
              type === 'number'
                ? onChange(parseFloat(e.target.value))
                : onChange(e.target.value as string)
            }
            onKeyDown={e => onKeyDown(e)}
            pattern={pattern}
            placeholder={placeholder}
            type={type}
            min={min}
            max={max}
            required={required}
            maxLength={maxLength}
            value={value}
            autoComplete={autoComplete ? 'on' : 'off'}
            disabled={disabled}
            readOnly={readOnly}
            ref={elemRef as RefObject<HTMLInputElement>}
            aria-invalid={!!error}
            step={step}
          />
        )}

        {loading && (
          <div className="pointer-events-none absolute right-0 top-0 mt-3 mr-3">
            <LoadingSpinner />
          </div>
        )}

        {success && (
          <div className="pointer-events-none absolute right-0 top-0 mt-3 mr-3">
            <MdCheck className="text-green-600" />
          </div>
        )}

        {failure && (
          <div className="pointer-events-none absolute right-0 top-0 mt-3 mr-3">
            <MdClose className="text-red-600" />
          </div>
        )}
      </div>

      {error && (
        <label
          htmlFor={_id}
          className={cx(
            'TextField__error text-error mt-1 block text-[.625rem] md:text-xs text-fire',
            {
              'opacity-0': !showError,
              'opacity-1': showError
            }
          )}
          role="alert"
        >
          {error}
        </label>
      )}
    </div>
  );
};

export default TextField;

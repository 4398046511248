import { Workflow } from 'escapod';

const ewisByInspectionId = (workflow: Workflow) =>
  workflow.costCenters.reduce((flattenedCostCenters: { [key: string]: string }, costCenter) => {
    const flattenedBillsOfProcess = costCenter.billsOfProcess.reduce(
      (flattenedBillsOfProcess: { [key: string]: string }, billOfProcess) => {
        const flattenedSteps = billOfProcess.steps.reduce(
          (flattenedSteps: { [key: string]: string }, step) => {
            const flattenedInspections = step.inspections.reduce(
              (flattenedInspections: { [key: string]: string }, inspection) => {
                return {
                  ...flattenedInspections,
                  [inspection._key]: step.ewi
                };
              },
              {}
            );
            return { ...flattenedSteps, ...flattenedInspections };
          },
          {}
        );
        return { ...flattenedBillsOfProcess, ...flattenedSteps };
      },
      {}
    );

    return { ...flattenedCostCenters, ...flattenedBillsOfProcess };
  }, {});

export default ewisByInspectionId;

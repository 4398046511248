import React, { useState, useContext, createContext, useMemo, FC, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

type Notification = {
  id: string;
  type: 'success' | 'warning' | 'error' | 'message';
  message: string;
  action?: string;
  callback?: () => void;
};

type State = { notifications: Notification[] };
const INITIAL_STATE: State = { notifications: [] };

type Mutate = {
  createNotification: (notification: Omit<Notification, 'id'>) => void;
  dismissNotification: (id: string) => void;
};

const NotificationsContext = createContext(INITIAL_STATE);
const NotificationsMutate = createContext<Mutate | null>(null);

const NotificationsContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [context, setContext] = useState(INITIAL_STATE);

  const mutate: Mutate = useMemo(
    () => ({
      createNotification: notification => {
        const id = uuid();

        setContext({
          ...context,
          notifications: [...context.notifications, { ...notification, id }]
        }),
          setTimeout(() => {
            setContext(context => {
              return {
                ...context,
                notifications: context.notifications.filter(notification => notification.id !== id)
              };
            });
          }, 10000);
      },
      dismissNotification: id =>
        setContext({
          ...context,
          notifications: context.notifications.filter(notification => notification.id !== id)
        })
    }),
    [context]
  );

  return (
    <NotificationsContext.Provider value={context}>
      <NotificationsMutate.Provider value={mutate}>{children}</NotificationsMutate.Provider>
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const state = useContext(NotificationsContext);

  if (state === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }

  return state;
};

export const useNotificationsMutate = () => {
  const mutate = useContext(NotificationsMutate);

  if (mutate === undefined) {
    throw new Error('useNotificationsMutate must be used within a NotificationsProvider');
  }

  return mutate;
};

export default NotificationsContextProvider;

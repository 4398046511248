import React, { FC, useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { MdMenu, MdClose, MdOutlineManageAccounts, MdOutlineLibraryAddCheck } from 'react-icons/md';
import PackageJson from './../package.json';

import { useCurrentUser } from 'contexts/UserContext';
import { useSearchMutate, useSearchState } from 'contexts/SearchContext';
import { Button } from 'components/base';
import podsLogo from '../public/images/pods-logo-2.png';
import avatar from '../public/images/escapod-avatar.png';
import { RiBug2Line, RiSearchLine, RiUserLine } from 'react-icons/ri';

const MenuHotspots = [
  { label: 'Dashboard', url: '/', icon: MdOutlineManageAccounts },
  { label: 'Manufacturing', url: '/manufacturing', icon: MdOutlineLibraryAddCheck }
];
const MenuLinks = [
  { label: 'Products', url: '/products' },
  { label: 'Product Lines', url: '/product-lines' },
  { label: 'Customers', url: '/customers' },
  { label: 'Orders', url: '/orders' },
  { label: 'Workflows', url: '/workflows' },
  { label: 'Facilities', url: '/facilities' },
  { label: 'Users', url: '/users' },
  { label: 'Tags', url: '/tags' }
];

const Nav: FC = () => {
  const session = useSession();
  const user = useCurrentUser();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
  const closeMenu = useCallback(() => setMenuIsOpen(false), []);
  const openMenu = useCallback(() => setMenuIsOpen(true), []);
  const toggleUserMenu = useCallback(() => setUserMenuIsOpen(!userMenuIsOpen), [userMenuIsOpen]);
  const { searchIsActive } = useSearchState();
  const searchMutate = useSearchMutate();

  const page = useRouter();
  useEffect(closeMenu, [page.asPath, closeMenu]);

  if (
    session.status === 'unauthenticated' ||
    session.status === 'loading' ||
    page.asPath.includes('login')
  ) {
    return null;
  }

  return (
    <div className="Nav z-50 relative animate-fade-in">
      <div className="Nav__top-bar bg-stone-900 text-white border-b-1 border-b-stone-400 h-12 z-30 fixed top-0 left-0 w-full">
        <div className="Nav__top-bar__inner flex items-center justify-between w-full">
          <div className="flex justify-start items-center w-full">
            <div className="Nav__menu-button border-r-1 border-r-stone-400">
              <Button
                variant="no-style"
                onClick={menuIsOpen ? closeMenu : openMenu}
                className="p-2 h-12 w-12 flex justify-center items-center hover:bg-stone-700"
              >
                {menuIsOpen ? <MdClose className="w-6 h-6" /> : <MdMenu className="w-6 h-6" />}
              </Button>
            </div>
            <div className="Nav__logo-wrapper mx-4 w-full flex justify-start">
              <div className="Nav__logo relative w-[66px] mt-[6px]">
                <Link href="/">
                  <a>
                    <Image src={podsLogo} alt="PODS" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="Nav__top-bar-menu flex items-center">
            <div className="hidden sm:flex pr-1">
              {MenuHotspots.map(hotspot => {
                const isActive =
                  page.asPath === '/'
                    ? page.asPath === hotspot.url
                    : hotspot.url !== '/' && page.asPath.includes(hotspot.url);

                return (
                  <Link href={hotspot.url} key={`Nav__top-bar-menu__item--${hotspot.url}`}>
                    <Button
                      variant="no-style"
                      className={cx('mr-2 flex items-center py-[2px] pl-[3px] pr-2', {
                        'hover:bg-stone-700': !isActive,
                        'bg-cyan-900 text-cyan-200': isActive
                      })}
                    >
                      <hotspot.icon className="mr-1 w-6 mt-[2px]" />
                      <span className="font-normal">{hotspot.label}</span>
                    </Button>
                  </Link>
                );
              })}
            </div>
            <Button
              variant="no-style"
              className="Nav__avatar px-3 border-l-1 border-l-stone-400 h-12 w-12 flex items-center justify-center flex-grow-0 text-stone-50 hover:bg-stone-700"
              onClick={() =>
                searchIsActive ? searchMutate?.reset() : searchMutate?.setSearchIsActive(true)
              }
            >
              {searchIsActive ? <MdClose /> : <RiSearchLine />}
            </Button>
            <Button
              variant="no-style"
              className="Nav__avatar px-3 border-l-1 border-l-stone-400 h-12 flex items-center flex-grow-0 hover:bg-stone-700"
              onClick={toggleUserMenu}
            >
              <div className="Nav__avatar__image-wrapper relative w-6 h-6">
                <Image src={avatar} alt="Avatar" />
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'Nav__user-menu fixed top-12 right-0 bg-stone-900 text-white p-8 w-[320px] z-30 transition-opacity',
          {
            'opacity-0 pointer-events-none': !userMenuIsOpen
          }
        )}
      >
        <div className="mb-6 border-b-1 border-stone-400 pb-6">
          <Link href="/profile" className="Nav__user-menu__profile flex items-center">
            <a className="flex items-center" onClick={toggleUserMenu}>
              <div className="Nav__avatar__image-wrapper relative w-6 h-6 mr-3">
                <Image src={avatar} alt="Avatar" />
              </div>
              <span className="text-sm">
                {user?.firstName} {user?.lastName}
              </span>
            </a>
          </Link>
        </div>
        <div className="mb-6">
          <Link href="/profile" className="Nav__user-menu__profile flex items-center">
            <a className="flex items-center" onClick={toggleUserMenu}>
              <RiUserLine className="mr-2" />
              <span className="text-xs">My Profile</span>
            </a>
          </Link>
        </div>
        <div className="mb-6">
          <span className="Nav__user-menu__profile flex items-center">
            <a
              target="_blank"
              rel="noreferrer nofollow"
              href="https://docs.google.com/document/d/1FrIyxCBc9rCn1lNOMfd_XMZBzGTs0ExoFecQpqODWsM/edit#heading=h.k62iyd1zogc"
              className="flex items-center"
              onClick={toggleUserMenu}
            >
              <RiBug2Line className="mr-2" />
              <span className="text-xs">Report an Issue</span>
            </a>
          </span>
        </div>
        <div className="pt-6 border-t-1 border-stone-400">
          <Button variant="neutral-small" onClick={() => signOut()}>
            Sign Out
          </Button>
        </div>
      </div>
      <div
        className={cx('Nav__menu-overlay fixed top-0 bottom-0 left-0 right-0', {
          'pointer-events-none': !menuIsOpen
        })}
        onClick={closeMenu}
      />
      <div
        className={cx(
          'Nav__menu bg-stone-900 text-white w-full max-w-[400px] transition-transform fixed top-0 left-0 z-20',
          {
            'translate-x-[-100%] pointer-events-none': !menuIsOpen
          }
        )}
      >
        <div className="Nav__menu__inner px-8 pt-24 h-screen overflow-auto relative">
          <ul className="Nav__menu__hotspots mb-16">
            {MenuHotspots.map(hotspot => (
              <li className="mb-8" key={`Nav__menu__item--${hotspot.url}`}>
                <span className="text-2xl">
                  <Link href={hotspot.url}>
                    <a onClick={closeMenu}>
                      <span
                        className={cx('flex items-center', {
                          'text-cyan-500': hotspot.url === page.asPath
                        })}
                      >
                        <hotspot.icon className="mr-3 w-6 mt-[2px]" />
                        {hotspot.label}
                      </span>
                    </a>
                  </Link>
                </span>
              </li>
            ))}
          </ul>
          <ul className="mb-24">
            {MenuLinks.map(link => (
              <li className="mb-4" key={`Nav__menu__item--${link.url}`}>
                <span
                  className={cx('text-lg', {
                    'text-cyan-500': link.url === page.asPath
                  })}
                >
                  <Link href={link.url}>
                    <a onClick={closeMenu}>{link.label}</a>
                  </Link>
                </span>
              </li>
            ))}
          </ul>
          <div className="Nav__menu__footer sticky top-[100%] left-0 pb-8">
            <span className="text-stone-400 text-3xs">
              &copy; {new Date().getFullYear()} Escapod Trailers, LLC.
            </span>
            <span className="ml-2 text-cyan-400 text-3xs font-mono">v{PackageJson.version}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;

import React, { FC } from 'react';
import cx from 'classnames';
import { FaCheck } from 'react-icons/fa';

type Props = {
  progress: number;
  className?: string;
  width?: number;
  colorless?: boolean;
};
const ProgressBar: FC<Props> = ({ progress, className = '', width = 100, colorless }) => {
  const flooredProgress = Math.floor(progress);
  const progressWidth = flooredProgress * (width / 100);
  const adjustmentBase100 = 0.55;
  const adjustment = (adjustmentBase100 * width) / 100;
  const adjustedProgressWidth = Math.max(progressWidth * adjustment, 0);

  return (
    <div className={cx('ProgressBar flex items-center', className)}>
      <div className="ProgressBar__progress relative" style={{ width }}>
        <div
          className={cx('h-1 w-full rounded-sm', {
            'bg-yellow-400': !colorless,
            'bg-stone-400': colorless
          })}
        />
        <div
          className={cx('absolute h-1 left-0 top-0 z-10 bg-stone-300 rounded-sm transition-all')}
          style={{
            width:
              flooredProgress === 100
                ? `${progressWidth - 20}px`
                : `${adjustedProgressWidth.toFixed(0)}px`
          }}
        />
        <div
          className={cx(
            'absolute border-1 z-10 transition-all -translate-y-1/2 top-[2px] h-5 rounded-lg flex justify-center drop-shadow-sm',
            {
              'w-5': flooredProgress === 100,
              'w-10': flooredProgress !== 100,
              'bg-emerald-200 border-transparent': !colorless && flooredProgress === 100,
              'bg-yellow-200 border-yellow-400 text-yellow-800':
                !colorless && flooredProgress !== 100,
              'bg-stone-100 border-stone-300': colorless
            }
          )}
          style={{
            left:
              flooredProgress === 100
                ? `${progressWidth - 20}px`
                : `${adjustedProgressWidth.toFixed(0)}px`
          }}
        >
          {flooredProgress === 100 ? (
            <FaCheck
              className={cx('w-2 mt-[1px]', {
                'text-emerald-600': !colorless,
                'text-stone-600': colorless
              })}
            />
          ) : (
            <strong className="text-2xs mt-[2px]">
              {(flooredProgress || 0).toFixed(0)}
              <span className="font-normal">%</span>
            </strong>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;

import {
  Inspection,
  Workflow,
  Order,
  WorkflowProgress,
  Progress,
  ProgressObj,
  Product
} from 'escapod';
import totalEstimatedTime from 'utils/totalEstimatedTime';

export const compress = (progress: Progress): string => JSON.stringify(progress);
export const expand = (progress: string) => JSON.parse(progress || '{}');

export const progressByBillOfProcess = (workflow: Workflow, product: Product) => {
  const workflowProgress = JSON.parse(product.progress || '{}')?.[workflow._id];

  return workflow.costCenters.reduce<{
    [billOfProcess: string]: ProgressObj;
  }>((progressByBillOfProcess, costCenter) => {
    const billsOfProcess = costCenter.billsOfProcess.reduce<{
      [billOfProcess: string]: ProgressObj;
    }>((_billsOfProcess, bill) => {
      if (!workflowProgress) return progressByBillOfProcess;

      const steps = bill.steps.filter(
        step => !!workflowProgress.steps?.[step._key] || workflowProgress.steps?.[step._key] === ''
      );
      const completedSteps = steps.filter(step => {
        return !!workflowProgress.steps[step._key];
      });

      const inspections = steps.reduce((flattenedInspections: Inspection[], step) => {
        const _inspections = step.inspections.filter(
          inspection =>
            !!workflowProgress.inspections?.[inspection._key] ||
            workflowProgress.inspections?.[inspection._key] === ''
        );
        return [...flattenedInspections, ..._inspections];
      }, []);
      const completedInspections = inspections.filter(inspection => {
        return (
          !!workflowProgress.inspections[inspection._key] &&
          !workflowProgress.inspections[inspection._key].includes('FAIL')
        );
      });

      const stepsTime = steps.reduce(totalEstimatedTime, 0);
      const inspectionTime = inspections.reduce(totalEstimatedTime, 0);

      const completedStepsTime = completedSteps.reduce(totalEstimatedTime, 0);
      const completedInspectionTime = completedInspections.reduce(totalEstimatedTime, 0);
      const completedTime = completedInspectionTime + completedStepsTime;

      const totalTime = stepsTime + inspectionTime;
      const progress = (completedTime / totalTime) * 100;

      const billOfProcessProgress = {
        totalTime,
        completedTime,
        progress,
        completedInspections,
        completedSteps
      };

      return { ..._billsOfProcess, [bill._key]: billOfProcessProgress };
    }, {});

    return { ...progressByBillOfProcess, ...billsOfProcess };
  }, {});
};

export const progressByCostCenter = (workflow: Workflow, product: Product) =>
  workflow.costCenters.reduce<{
    [costCenter: string]: Pick<ProgressObj, 'totalTime' | 'completedTime'>;
  }>((progressByCostCenter, costCenter) => {
    const costCenterProgress: Pick<ProgressObj, 'totalTime' | 'completedTime'> =
      costCenter.billsOfProcess.reduce(
        (costCenterProgress, billOfProcess) => {
          const _progressByBillOfProcess = progressByBillOfProcess(workflow, product);
          const billOfProcessProgress = _progressByBillOfProcess[billOfProcess._key];

          if (!billOfProcessProgress) return costCenterProgress;

          const { totalTime, completedTime } = billOfProcessProgress;

          return {
            totalTime: costCenterProgress.totalTime + totalTime,
            completedTime: costCenterProgress.completedTime + completedTime
          };
        },
        { totalTime: 0, completedTime: 0 }
      );

    return { ...progressByCostCenter, [costCenter._key]: costCenterProgress };
  }, {});

// TO-DO: Unit test
export const generateFromWorkflow = (workflow: Workflow, order: Order): WorkflowProgress => {
  if (!workflow) throw new Error('Progress.generateFromWorkflow: Requires Workflow');
  if (!order) throw new Error('Progress.generateFromWorkflow: Requires Order');

  const skus =
    order.items?.reduce<string[]>(
      (_skus, item) => (!!item.sku ? [..._skus, item.sku] : _skus),
      []
    ) || [];

  const steps = workflow.costCenters.reduce(
    (flattenedCostCenters: { [key: string]: string }, costCenter) => {
      const flattenedBillsOfProcess = costCenter.billsOfProcess.reduce(
        (flattenedBillsOfProcess: { [key: string]: string }, billOfProcess) => {
          const flattenedSteps = billOfProcess.steps.reduce(
            (flattened: { [key: string]: string }, step) => {
              const hasConflicts =
                !!step.conflicts?.length &&
                step.conflicts?.some(conflict => skus.includes(conflict));
              const hasMissingDependencies =
                !!step.dependencies?.length && !step.dependencies?.every(dep => skus.includes(dep));
              if (!hasConflicts && !hasMissingDependencies) flattened[step._key] = '';

              return flattened;
            },
            {}
          );
          return { ...flattenedBillsOfProcess, ...flattenedSteps };
        },
        {}
      );
      return { ...flattenedCostCenters, ...flattenedBillsOfProcess };
    },
    {}
  );

  const inspections = workflow.costCenters.reduce(
    (flattenedCostCenters: { [key: string]: string }, costCenter) => {
      const flattenedBillsOfProcess = costCenter.billsOfProcess.reduce(
        (flattenedBillsOfProcess: { [key: string]: string }, billOfProcess) => {
          const flattenedSteps = billOfProcess.steps.reduce(
            (flattenedSteps: { [key: string]: string }, step) => {
              const flattenedInspections = step.inspections.reduce(
                (flattened: { [key: string]: string }, inspection) => {
                  const hasConflicts =
                    !!inspection.conflicts?.length &&
                    inspection.conflicts?.some(conflict => skus.includes(conflict));
                  const hasMissingDependencies =
                    !!inspection.dependencies?.length &&
                    !inspection.dependencies?.every(dep => skus.includes(dep));
                  if (!hasConflicts && !hasMissingDependencies) flattened[inspection._key] = '';
                  return flattened;
                },
                {}
              );

              return { ...flattenedSteps, ...flattenedInspections };
            },
            {}
          );
          return { ...flattenedBillsOfProcess, ...flattenedSteps };
        },
        {}
      );
      return { ...flattenedCostCenters, ...flattenedBillsOfProcess };
    },
    {}
  );

  return { steps, inspections };
};

import { Workflow, Inspection } from 'escapod';

export const inspectionsByKey = (workflow: Workflow) =>
  workflow.costCenters.reduce<{
    [key: string]: Inspection;
  }>((costCenters, costCenter) => {
    const billsOfProcess = costCenter.billsOfProcess.reduce<{
      [key: string]: Inspection;
    }>((billsOfProcess, billOfProcess) => {
      const steps = billOfProcess.steps.reduce<{
        [key: string]: Inspection;
      }>((steps, step) => {
        const inspections = step.inspections.reduce<{
          [key: string]: Inspection;
        }>((inspections, inspection) => {
          return { ...inspections, [inspection._key]: inspection };
        }, {});
        return { ...steps, ...inspections };
      }, {});
      return { ...billsOfProcess, ...steps };
    }, {});
    return { ...costCenters, ...billsOfProcess };
  }, {});

export default inspectionsByKey;
